import { Container } from "@material-ui/core"
import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import Breadcrumb from "../components/Breadcrumb"
import { Layout } from "../components/layout"

const NotFoundPage = ({ data, location }) => {
  const breadcrumbData = [
    {
      title: "Home",
      url: "/",
    },
    {
      title: "Password Reset Success",
      url: "/forgotten-password",
    },
  ]
  return (
    <Layout title={"Graduate Fashion Week - 404"}>
      <div style={{ backgroundColor: "#FAFAFA", width: "100%" }}>
        <Container maxWidth={"lg"}>
          <Breadcrumb data={breadcrumbData} />
        </Container>
      </div>
      <StyledContainer>
        <h2>Success</h2>
        <h5>Your password has been changed. </h5>
        <h5>
          <Link to={"/events/login"}>Login here</Link>
        </h5>
      </StyledContainer>
    </Layout>
  )
}

const StyledContainer = styled(Container)`
  padding-top: 120px;
  padding-bottom: 120px;
  text-align: center;
`

export default NotFoundPage
